import React, { useState, useEffect } from "react";
import {
  Container,
  Loader,
  Segment,
  Grid,
  Divider,
  Modal,
  Button,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as appActions from "../../redux/actions/appActions";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import TestataPagina from "../common/TestataPagina";
import GruppoSelezione from "../common/GruppoSelezione";
import GrigliaMaestri from "./GrigliaMaestri";
import useWindowSize from "../../utils/useWindowSize";
import * as skiworkUtils from "../../utils/skiworkUtils";
import { useTranslation } from "react-i18next";
import defaultImgCorso from "../../assets/images/defaults/defaultCourse.jpg";
import RedirectWithTrack from "../common/RedirectWithTrack";

function PrivateMain({
  codScuola,
  lang,
  appData,
  datiScuola,
  loading,

  setSlug,
  setLang,
  loadSchoolData,
  clearAppData,
  bookReset,
  setPrefsPrivate,

  ...props
}) {
  //////////////////////////////////////////////
  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [redirectToProgramma, setRedirectToProgramma] = useState(false);
  const windowSize = useWindowSize();
  const [preferenze, setPreferenze] = useState({});
  const [listaLingue, setListaLingue] = useState([]);
  const [listaSpecialita, setListaSpecialita] = useState([]);
  const [listaSesso, setListaSesso] = useState([]);
  // const [oldSesso, setOldSesso] = useState(false);
  const [showMaestri, setShowMaestri] = useState(false);
  const [tipoFiltro, setTipoFiltro] = useState("");

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);
  const picUrl = `https://skiwork.blob.core.windows.net/webimg/${codScuola.toUpperCase()}/TipiCorso/PICTURE_PRIVATE.jpg`;

  function addDefaultSrc(ev) {
    ev.target.src = defaultImgCorso;
  }

  //////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
    bookReset();

    const sesso = [
      {
        text: "NO PREF",
        img: require(`../../assets/images/defaults/free.jpg`),
        key: "",
      },
      {
        text: "M",
        img: require(`../../assets/images/people/Male.jpg`),
        key: "M",
      },
      {
        text: "F",
        img: require(`../../assets/images/people/Female.jpg`),
        key: "F",
      },
    ];

    if (windowSize.width >= 600) {
      sesso.push({
        text: t("oppure_maestro"),
        img: undefined,
        key: "S",
        width: "194px",
      });
    }
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    if (urlParams.has("offers")) {
      setTipoFiltro(
        urlParams.get("offers").toLowerCase() === "true" ? "O" : "N"
      );
    } else {
      setTipoFiltro("");
    }

    setListaSesso(sesso);
  }, []);

  useEffect(() => {
    // if (preferenze.idMaestro > 0) {
    //   handleAvantiClick();
    // }
  }, [preferenze]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  useEffect(() => {
    if (datiScuola === null || datiScuola === undefined) return;
    if (!datiScuola?.hasOwnProperty("codiceScuola")) return;
    if (
      datiScuola?.codiceScuola?.toLowerCase() !== appData?.slug?.toLowerCase()
    )
      return;

    setPreferenze({
      idLingua: "",
      lingua: "",
      idSesso: "",
      sesso: "",
      idSpecialita: datiScuola.specialita[0].idspecialita.toString(), //"1",
      specialita: datiScuola.specialita[0].descrSpecialita, //datiScuola.specialita.find((x) => x.idspecialita === 1)
      //.descrSpecialita,
      idMaestro: -1,
    });

    let spec = [
      // {
      //   text: "NO PREF",
      //   img: require(`../../assets/images/defaults/free.jpg`),
      //   key: "",
      // },
    ];
    spec = spec.concat(
      datiScuola.specialita.map((s) => {
        return {
          key: s.idspecialita.toString(),
          img: require(`../../assets/images/specialita/${
            s.simbolo === null ? "0" : s.simbolo
          }b.jpg`),
          text: skiworkUtils.getDescrizioneSpecialita(
            s,
            datiScuola.idLinguaInUso
          ),
        };
      })
    );
    setListaSpecialita(spec);

    const lingue = [
      {
        text: "NO PREF",
        img: require(`../../assets/images/defaults/free.jpg`),
        key: "",
      },
    ];
    for (let key in datiScuola.lingueMaestro) {
      const isoCode = datiScuola.lingueMaestro[key].toUpperCase();
      lingue.push({
        text: isoCode,
        img: require(`../../assets/images/flags/${key}.jpg`),
        key: key,
      });
    }
    setListaLingue(lingue);
  }, [datiScuola]);

  ///////////////////////////////////////////
  //HANDLERS
  function handleSpecialitaChange(key) {
    setPreferenze({
      ...preferenze,
      idSpecialita: key,
      specialita: listaSpecialita.find((x) => x.key === key).text,
    });
  }
  function handleLinguaChange(key) {
    setPreferenze({
      ...preferenze,
      lingua: listaLingue.find((x) => x.key === key).text,
      idLingua: key,
    });
  }
  function handleSessoChange(key) {
    if (key === "S") {
      setShowMaestri(true);
    } else {
      setPreferenze({
        ...preferenze,
        idSesso: key,
        sesso: listaSesso.find((x) => x.key === key).text,
      });
    }
  }
  function handleAvantiClick() {
    const preferenzeTmp = { ...preferenze };
    preferenzeTmp.idLingua =
      preferenzeTmp.idLingua === "" ? 0 : parseInt(preferenzeTmp.idLingua);

    preferenzeTmp.maestro = undefined;
    setPrefsPrivate(preferenzeTmp);
    setRedirectToProgramma(true);
  }
  function handleMaestroSelect(id, maestro) {
    const preferenzeTmp = { ...preferenze };

    const prefsTmp = {
      ...preferenze,
      idMaestro: id,
      maestro: maestro,
      idLingua:
        preferenzeTmp.idLingua === "" ? 0 : parseInt(preferenzeTmp.idLingua),
    };

    setPrefsPrivate(prefsTmp);
    setRedirectToProgramma(true);
  }

  if (
    loading ||
    appData.slug === undefined ||
    isLoading ||
    datiScuola === null ||
    datiScuola === undefined ||
    Object.keys(datiScuola).length === 0
  ) {
    return <Loader active></Loader>;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        {redirectToProgramma && tipoFiltro === "O" && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/private/programma?offers=true`}
            push
          />
        )}
        {redirectToProgramma && tipoFiltro === "N" && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/private/programma?offers=false`}
            push
          />
        )}
        {redirectToProgramma && tipoFiltro === "" && (
          <RedirectWithTrack
            to={`/${appData.lang}/${appData.slug}/private/programma`}
            push
          />
        )}

        <TestataPagina
          titolo={t("lezioni_private")}
          icon="user"
          sottoTitolo={t("scegli_preferenze_private")}
        />

        <Segment
          className="padding_0_lr padding_0_top contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          <Grid stackable centered columns={1}>
            {/*<Grid.Column width={7} textAlign="center">
              <div
                style={{
                  width: "100%",
                  height: "0",
                  paddingBottom: "66%",
                  position: "relative",
                }}
              >
                <img
                  onError={(i) => {
                    i.target.style.display = "none";
                  }}
                  src={picUrl}
                  alt=" "
                  className="skiwork-image"
                  width="100%"
                  height="100%"
                ></img>
              </div>
                </Grid.Column>*/}
            <Grid.Row>
              <Grid.Column width={9}>
                <Segment inverted textAlign="center" className="skiwork-header">
                  {t("preferenze_lezione")}
                </Segment>
                <GruppoSelezione
                  width={97}
                  elementi={listaSpecialita}
                  onChange={handleSpecialitaChange}
                  selezione={preferenze.idSpecialita}
                  titolo={t("scegli_specialita")}
                ></GruppoSelezione>

                <Divider />
                <GruppoSelezione
                  width={97}
                  elementi={listaLingue}
                  onChange={handleLinguaChange}
                  selezione={preferenze.idLingua}
                  titolo={t("scegli_lingua")}
                ></GruppoSelezione>

                <Divider />
                {/* <div style={{ display: "flex" }}> */}
                <GruppoSelezione
                  width={97}
                  elementi={listaSesso}
                  onChange={handleSessoChange}
                  selezione={preferenze.idSesso}
                  titolo={t("tipo_maestro")}
                ></GruppoSelezione>
                {windowSize.width < 600 && (
                  <>
                    <Divider horizontal>
                      <label>{t("or")}</label>
                    </Divider>
                    <Modal
                      trigger={
                        <div style={{ textAlign: "center" }}>
                          <Button
                            basic
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            <p>{t("oppure_maestro")}</p>
                          </Button>
                        </div>
                        // <p style={{ cursor: "pointer" }}>{t("oppure_maestro")}</p>
                      }
                      closeIcon
                      centered={false}
                      size="fullscreen"
                      // open={preferenze.idSesso === "F"}
                    >
                      <Modal.Header>{t("lista_maestri")}</Modal.Header>
                      <Modal.Content>
                        <GrigliaMaestri
                          codScuola={datiScuola.codiceScuola}
                          idScuola={datiScuola.id}
                          onSelect={handleMaestroSelect}
                          idSpecialita={parseInt(preferenze.idSpecialita)}
                          t={t}
                        ></GrigliaMaestri>
                      </Modal.Content>
                    </Modal>
                  </>
                )}
                {windowSize.width >= 600 && (
                  <Modal
                    closeIcon
                    centered={false}
                    size="fullscreen"
                    open={showMaestri}
                    onClose={() => {
                      console.log("close!");
                      setShowMaestri(false);
                    }}
                    onOpen={() => {
                      console.log("open!");
                    }}
                  >
                    <Modal.Header>{t("lista_maestri")}</Modal.Header>
                    <Modal.Content>
                      <GrigliaMaestri
                        codScuola={datiScuola.codiceScuola}
                        idScuola={datiScuola.id}
                        onSelect={handleMaestroSelect}
                        idSpecialita={parseInt(preferenze.idSpecialita)}
                        t={t}
                      ></GrigliaMaestri>
                    </Modal.Content>
                  </Modal>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid stackable centered columns={1}>
            <Grid.Row>
              <Grid.Column width={9} textAlign="right">
                <Button
                  primary
                  icon
                  size="huge"
                  onClick={handleAvantiClick}
                  disabled={preferenze.idSpecialita <= 0}
                >
                  {t("avanti")} <Icon name="arrow right"></Icon>
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <p
            style={{
              textAlign: windowSize.width > 600 ? "right" : "center",
              marginTop: "14px",
            }}
          ></p>
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  loadSchoolData: scuolaActions.loadSchoolData,
  clearAppData: appActions.clearAppData,
  bookReset: appActions.bookReset,
  setPrefsPrivate: appActions.setPrefsPrivate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateMain);
