export const validateProvincia = (provincia) => {
  // Clean and uppercase input
  provincia = provincia.toUpperCase();
  if (provincia === "") return false;
  if (provincia.length !== 2) return false;
  if (provincia.match(/[^A-Z]/)) return false;
  // Check if the input is a valid ITALIAN province code

  const validProvinceCodes = [
    "AG",
    "AL",
    "AN",
    "AO",
    "AR",
    "AP",
    "AT",
    "AV",
    "BA",
    "BT",
    "BL",
    "BN",
    "BG",
    "BI",
    "BO",
    "BZ",
    "BS",
    "BR",
    "CA",
    "CL",
    "CB",
    "CE",
    "CT",
    "CZ",
    "CH",
    "CO",
    "CS",
    "CR",
    "KR",
    "CN",
    "EN",
    "FM",
    "FE",
    "FI",
    "FG",
    "FC",
    "FR",
    "GE",
    "GO",
    "GR",
    "IM",
    "IS",
    "SP",
    "AQ",
    "LT",
    "LE",
    "LC",
    "LI",
    "LO",
    "LU",
    "MC",
    "MN",
    "MS",
    "MT",
    "ME",
    "MI",
    "MO",
    "MB",
    "NA",
    "NO",
    "NU",
    "OR",
    "PD",
    "PA",
    "PR",
    "PV",
    "PG",
    "PU",
    "PE",
    "PC",
    "PI",
    "PT",
    "PN",
    "PZ",
    "PO",
    "RG",
    "RA",
    "RC",
    "RE",
    "RI",
    "RN",
    "RM",
    "RO",
    "SA",
    "SS",
    "SV",
    "SI",
    "SR",
    "SO",
    "SU",
    "TA",
    "TE",
    "TR",
    "TO",
    "TP",
    "TN",
    "TV",
    "TS",
    "UD",
    "VA",
    "VE",
    "VB",
    "VC",
    "VR",
    "VV",
    "VI",
    "VT",
  ];

  return validProvinceCodes.includes(provincia.toUpperCase());
};
